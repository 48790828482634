<template>
  <div>
    <Loader v-if="!show"/>
    <div v-else class="wrapper" :style="`background:url(${require('@/assets/images/background.png')}); background-attachment: fixed; background-size: cover;`"> 
      <SidebarStyle />
      <HeaderStyle1/>
      <div class="content-page">
        <transition name="router-anim">
          <router-view/>
        </transition>
      </div>
      <FooterStyle />
    </div>
  </div>
</template>
<script>
import Loader from '@/components/loader/Loader'
import SidebarStyle from "@/components/partials/backend/SidebarStyle/SidebarStyle";
import HeaderStyle1 from "@/components/partials/backend/HeaderStyle/HeaderStyle1";
import FooterStyle from "@/components/partials/backend/FooterStyle/FooterStyle";
export default {
  name: 'Layout-2',
  components: {
    HeaderStyle1,
    FooterStyle,
    SidebarStyle,
    Loader
  },
  data() {
    return {
      show: false,
    };
  },
   mounted(){
    document.body.classList='';
    setTimeout(() => {
        this.show = true;
      }, 1500);
  },
  destroyed () {
    document.body.classList=''
  },
}
</script>
<style>
</style>
