<template>
   <footer class="iq-footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <img class="w-50" src="@/assets/images/footer.png" alt="">
            </div>
        </div>
    </div>
</footer>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name:"Footer",
    prop:{
    },
    computed : {
        ...mapGetters({
            appName: 'appName'
        })
    }
}
</script>