<template>
  <div class="iq-sidebar  sidebar-default ">
    <div class="iq-sidebar-logo d-flex align-items-center ">
      <router-link :to="{name: 'layout.dashboard1'}" class="header-logo">
        <img :src="logo" alt="logo">
      </router-link>
      <div class="iq-menu-bt-sidebar ml-0">
        <i class="las la-bars wrapper-menu"></i>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
        <nav class="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" class="iq-menu">
                <li :class="checkActive('layout.dashboard1') ? 'active' : ''">
                    <router-link :to="{name: 'layout.dashboard1'}" class="svg-icon">                        
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                            width="20" height="20">
                            <path
                                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="ml-3">Painel de controlo</span>
                    </router-link>
                </li>
                <li :class="checkActive('app.invoicing-list') ? 'active' : ''">
                    <router-link :to="{name: 'app.invoicing-list'}" class="svg-icon">    
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                            width="20" height="20">
                            <path
                                d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                                clip-rule="evenodd" />
                        </svg>                  
                        <span class="ml-3">Faturação</span>
                    </router-link>
                </li>
                <li :class="checkActive('app.payments-list') ? 'active' : ''">
                    <router-link :to="{name: 'app.payments-list'}" class="svg-icon">                        
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                            width="20" height="20">
                            <path
                                d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="ml-3">Contabilidade</span>
                    </router-link>
                </li>
                <li :class="checkActive('app.salaries-list') ? 'active' : ''">
                    <router-link :to="{name: 'app.salaries-list'}" class="svg-icon">                        
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                            width="20" height="20">
                            <path
                                d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="ml-3">Salários</span>
                    </router-link>
                </li>
                <li :class="checkActive(humanResources) ? 'active' : ''">
                    <a v-b-toggle.humanResources class="collapsed svg-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                            width="20" height="20">
                            <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                            <path
                                d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                        </svg>
                        <span class="ml-3">Recursos humanos</span>
                        <i class="las la-angle-right iq-arrow-right arrow-active"></i>
                        <i class="las la-angle-down iq-arrow-right arrow-hover"></i>
                    </a>
                    <b-collapse tag="ul" id="humanResources" class="iq-submenu" accordion="child-accordion">
                        <li :class="checkActive('app.employees-list') ? 'active' : ''">
                            <router-link :to="{name: 'app.employees-list'}">
                                <i class="las la-minus"></i><span class="">Lista de funcionários</span>
                            </router-link>
                        </li>
                        <li :class="checkActive('app.vacations-map') ? 'active' : ''">
                            <router-link :to="{name: 'app.vacations-map'}" class="svg-icon">
                                <i class="las la-minus"></i><span class="">Mapa de férias</span>
                            </router-link>
                        </li>
                        <li :class="checkActive('app.vacations-map') ? 'active' : ''">
                            <router-link :to="{name: 'app.absences-map'}" class="svg-icon">
                                <i class="las la-minus"></i><span class="">Mapa de faltas</span>
                            </router-link>
                        </li>
                        <li :class="checkActive('app.learning-map') ? 'active' : ''">
                            <router-link :to="{name: 'app.learning-map'}" class="svg-icon">
                                <i class="las la-minus"></i><span class="">Mapa de formações</span>
                            </router-link>
                        </li>
                        <li :class="checkActive('app.task-board') ? 'active' : ''">
                            <router-link :to="{name: 'app.task-board'}" class="svg-icon">
                                <i class="las la-minus"></i><span class="">Gestor de tarefas</span>
                            </router-link>
                        </li>
                    </b-collapse>
                </li>
                <li :class="checkActive('app.articles-list') ? 'active' : ''">
                    <router-link :to="{name: 'app.articles-list'}" class="svg-icon">                        
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                                width="20" height="20">
                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>
                        <span class="ml-3">Artigos</span>
                    </router-link>
                </li>
                <li :class="checkActive(contacts) ? 'active' : ''">
                    <a v-b-toggle.contacts class="collapsed" >
                        <svg class="svg-icon" height="20" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                        <span class="ml-3">Contactos/leads</span>
                        <i class="las la-angle-right iq-arrow-right arrow-active"></i>
                        <i class="las la-angle-down iq-arrow-right arrow-hover"></i>
                    </a>
                    <b-collapse tag="ul" id="contacts" class="iq-submenu" accordion="child-accordion">
                        <li :class="checkActive('app.suppliers-list') ? 'active' : ''">
                            <router-link :to="{name: 'app.suppliers-list'}">
                                <i class="las la-minus"></i><span>Fornecedores</span>
                            </router-link>
                        </li>
                        <li :class="checkActive('app.clients-list') ? 'active' : ''">
                            <router-link :to="{name: 'app.clients-list'}">
                                <i class="las la-minus"></i><span>Clientes</span>
                            </router-link>
                        </li>
                    </b-collapse>
                </li>
                <li :class="checkActive(marketing) ? 'active' : ''">
                    <a v-b-toggle.marketing class="collapsed" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                            width="20" height="20">
                            <path fill-rule="evenodd"
                                d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                                clip-rule="evenodd" />
                            <path
                                d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                        </svg>
                        <span class="ml-3">Marketing</span>
                        <i class="las la-angle-right iq-arrow-right arrow-active"></i>
                        <i class="las la-angle-down iq-arrow-right arrow-hover"></i>
                    </a>
                    <b-collapse tag="ul" id="marketing" class="iq-submenu" accordion="child-accordion">
                            <li :class="checkActive('pages.marketing') ? 'active' : ''">
                                <router-link :to="{name: 'app.marketingWebsite'}">
                                    <i class="las la-minus"></i><span>Website</span>
                                </router-link>
                            </li>
                            <li :class="checkActive('pages.marketing') ? 'active' : ''">
                                <router-link :to="{name: 'app.marketingSocials'}">
                                    <i class="las la-minus"></i><span>Redes</span>
                                </router-link>
                            </li>
                            <li :class="checkActive('app.manage-networks') ? 'active' : ''">
                                <router-link :to="{name: 'app.manage-networks'}">
                                    <i class="las la-minus"></i><span>Gerir redes sociais</span>
                                </router-link>
                            </li>
                    </b-collapse>
                </li>
                <li>
                    <a href="https://app.crisp.chat/initiate/login/" target="_blank" class="svg-icon">                        
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                                width="20" height="20">
                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>
                        <span class="ml-3">Chat</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {core} from '@/config/pluginInit'
export default {
  name:"SidebarStyle",
  data () {
    return{
      invoicing:['app.invoicing-list'],
      salaries:['app.salaries-list'],
      humanResources: ['app.employees-list'],
      articles: ['app.articles-list'],
      marketing: ['app.manage-networks'],
      documents: ['app.documents-list'],
      contacts: ['app.suppliers-list', 'app.clients-list']
    }
  },
  mounted () {
    core.SmoothScrollbar()
    core.changesidebar()
  },
   destroyed () {
    core.SmoothScrollbar()
    core.changesidebar()
  },
  computed : {
        ...mapGetters({
            appName: 'appName',
            logo:'logo'
        })
    },
    methods: {
    checkActive(route) {
        if (this.$route.name == route) {
            return true;
        }
        if (route.includes(this.$route.name)) {
            return true
        }
    }
}

}
</script>
<style scoped>
li{
    cursor: pointer;
}
</style>
